import "./contacts.scss";

const Contacts = ({ toggleContacts, isVisible }) => {
  return (
    <div
      className={`contacts-wrapper text-center ${isVisible ? "visible" : ""}`}
    >
      <img
        src={process.env.PUBLIC_URL + "images/icon-cross.png"}
        alt="Schovat kontakty"
        className="close-icon"
        onClick={toggleContacts}
      />
      <h1>Kontakty</h1>
      <a href="mailto:patrik@birula.cz">
        <img
          src={process.env.PUBLIC_URL + "images/icon-email.png"}
          alt="Email - patrik@birula.cz"
        />
        <h2>Email: patrik@birula.cz</h2>
      </a>
      <a href="tel:+420739715955">
        <img
          src={process.env.PUBLIC_URL + "images/icon-call.png"}
          alt="Telefon +420 739 715 955"
        />
        <h2>Telefon: +420 739 715 955</h2>
      </a>
    </div>
  );
};

export default Contacts;
